import React from "react";
import ModalVideo from "react-modal-video";
import "../node_modules/react-modal-video/css/modal-video.min.css";

class PopUpModal extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="e_dxfVJTfow"
          onClose={() => this.setState({ isOpen: false })}
        />
        <button href="#" className="video-link" onClick={this.openModal}>
          TAKE A VIDEO TOUR!
        </button>
      </div>
    );
  }
}

export default PopUpModal;
