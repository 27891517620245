import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import AccessForm from "./AccessForm";
import PopUpModal from "./PopUpModal";
import "./App.css";

const App = () => (
  <Container fluid>
    <Row>
      <Col md={7} className="main-content">
        <Container className="header-bar">
          <Row>
            <Col>
              <Image className="logo" fluid src="/logo.png" />
            </Col>
            <Col className="video-tour-link">
              <PopUpModal />
            </Col>
          </Row>
          <Row className="main-description">
            <Col>
              <p>
                The world‘s first shipping container home gym is here!
                [FITAINER] was build and designed by life long competitive
                athletes using commercial grade equipment inside of a modified
                20x8 ft shipping container. The [FITAINER] is perfect for
                fitness enthusiasts, trainers & coaches!
              </p>
              <ul className="main-desc-list">
                <li>No more time wasted on frustrating drives to the gym!</li>
                <li>No more sharing equipment with messy strangers!</li>
                <li>Delivered to your property intact & ready to use!</li>
                <li>
                  Cost-effective and cheaper than monthly household gym
                  membership!
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Col>
      <Col md={5} className="request-access-form">
        <Container>
          <h2>Request Early Access</h2>
          <p>
            Pay as little as <b>$349 for 60 Months</b>!
          </p>
          <AccessForm />
        </Container>
      </Col>
    </Row>
  </Container>
);

export default App;
