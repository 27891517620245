import React from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

class AccessForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { fullName: "", email: "", phone: "", zipCode: "" },
      errors: {},
      isLoading: false,
      success: false,
      failure: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  isLoading() {
    return this.state.isLoading;
  }

  handleChange(event) {
    this.setState({ success: false, failure: "" });
    let fields = this.state.fields;
    let errors = this.state.errors;
    errors[event.target.name] = "";
    fields[event.target.name] = event.target.value;
    this.setState({ fields, errors });
  }

  handleSubmit(event) {
    event.preventDefault();
    let fields = this.state.fields;
    if (this.validate()) {
      this.setState({ isLoading: true });
      axios
        .post("https://fitainer.herokuapp.com/", fields)
        .then(response => {
          for (let f in fields) fields[f] = "";
          this.setState({ fields });
          this.setState({ isLoading: false });
          this.setState({ success: true });
          this.setState({ failure: "" });
        })
        .catch(error => {
          this.setState({ failure: error.response.data.message });
          this.setState({ isLoading: false });
        });
    }
  }

  validate() {
    let errors = this.state.errors;
    let hasErrors = false;
    let fields = this.state.fields;
    if (!fields.fullName.length) {
      errors["fullName"] = "Name is a required field.";
      hasErrors = true;
    }
    if (!fields.email.length) {
      errors["email"] = "Please enter a email address.";
      hasErrors = true;
    }
    if (!/\S+@\S+\.\S+/.test(fields.email)) {
      errors["email"] = "Please enter a valid email address.";
      hasErrors = true;
    }
    if (!fields.phone.length) {
      errors["phone"] = "Please enter a phone number.";
      hasErrors = true;
    }
    if (
      !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(fields.phone)
    ) {
      errors["phone"] = "Please enter a 10 digit phone number.";
      hasErrors = true;
    }
    if (!fields.zipCode.length) {
      errors["zipCode"] = "Please enter a US zip code.";
      hasErrors = true;
    }
    if (!/^\d{5}$|^\d{5}-\d{4}$/.test(fields.zipCode)) {
      errors["zipCode"] = "Please enter a valid US zip code.";
      hasErrors = true;
    }
    if (hasErrors) {
      this.setState({ errors: errors });
      return false;
    }
    return true;
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        {this.state.success && (
          <Alert variant="success">
            Thank you for your interest in FITAINER!
          </Alert>
        )}
        {this.state.failure.length > 0 && (
          <Alert variant="danger">{this.state.failure}</Alert>
        )}
        <Form.Group>
          {this.state.errors.fullName && (
            <Alert variant="danger">{this.state.errors.fullName}</Alert>
          )}
          <Form.Control
            name="fullName"
            value={this.state.fields.fullName}
            onChange={this.handleChange}
            className="form-box"
            size="lg"
            type="text"
            placeholder="full name"
          />
          <br />
          {this.state.errors.email && (
            <Alert variant="danger">{this.state.errors.email}</Alert>
          )}
          <Form.Control
            name="email"
            value={this.state.fields.email}
            onChange={this.handleChange}
            className="form-box"
            size="lg"
            type="email"
            placeholder="email"
          />
          <br />
          {this.state.errors.phone && (
            <Alert variant="danger">{this.state.errors.phone}</Alert>
          )}
          <Form.Control
            name="phone"
            value={this.state.fields.phone}
            onChange={this.handleChange}
            className="form-box"
            size="lg"
            type="tel"
            placeholder="phone"
          />
          <br />
          {this.state.errors.zipCode && (
            <Alert variant="danger">{this.state.errors.zipCode}</Alert>
          )}
          <Form.Control
            name="zipCode"
            value={this.state.fields.zipCode}
            onChange={this.handleChange}
            className="form-box"
            size="lg"
            type="text"
            placeholder="zip code"
          />
        </Form.Group>
        <Button
          className="btn-access"
          variant="primary"
          type="submit"
          disabled={this.isLoading()}
        >
          GET ACCESS
        </Button>
      </Form>
    );
  }
}

export default AccessForm;
